<template>
	<div :class='{"a-booking-form-transfers-edit": edit}'>
		<div class='a-booking-form-direction'>
			<div class='row'>
				<div class="col-auto" v-for="item in items_types" :key='item.id'>
					<span class='a-booking-form-direction-link'
						:class='{"active": item.id === form.type}'
						@click='form.type = item.id, changeTypes()'
					>{{ item.name }}</span>
				</div>
			</div>
		</div>
		<form>
			<div class='a-booking-form-row'>
				<div class="a-booking-form-col">
					<select-defaults
						:placeholder="$t('account.booking.content.departure')"
						v-model='form.transferFrom'
						:requestName='getAutocompleteTransferPoint'
						:errors='!form.transferFrom && errors.transferFrom'
						isCode
					/>
				</div>
				<div class="a-booking-form-col">
					<select-defaults
						:placeholder="$t('account.booking.content.arrivale')"
						v-model='form.transferTo'
						:requestName='getAutocompleteTransferPoint'
						:errors='!form.transferTo && errors.transferTo'
						isCode
					/>
				</div>
				<div class="a-booking-form-col">
					<a-date-picker
						v-model='form.date'
						@change='changeDate($event)'
						:errors='!form.date && errors.dateFrom'
						:placeholder="form.type === 'both' ? `${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}` : `${$t('account.form.placeholder.dateFrom')}`"
						:range='form.type === "both"'
						:right='edit'
						class='mobile-hide'
					/>
					<a-date-picker-mobile
						v-model='form.date'
						@change='changeDate($event)'
						:errors='!form.date && errors.dateFrom'
						:placeholder="form.type === 'both' ? `${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}` : `${$t('account.form.placeholder.dateFrom')}`"
						:range='form.type === "both"'
						class='mobile-show'
					/>
				</div>
				<div class="a-booking-form-col" v-if='!edit' :style='form.tourists && form.tourists.length ? "pointer-events: none; opacity: .5": ""'>
					<select-people
						:adults='form.adults'
						:childrenCount='form.childrenCount'
						:children='form.children'
						:placeholder='$t("account.booking.content.count_tourist")'
						@update-people='$event => form = {...form, ...$event}'
						compact
						:errors='!form.adults && errors.adults'
						:tourists='form.tourists'
					/>
				</div>
			</div>

			<template v-if='!edit'>
				<select-tourist
					:initialTourists='form.tourists'
					@tourists='form.tourists = $event'
					@add-tourist='form.travelersIds = $event'
					@remove-tourist='form.travelersIds.splice($event, 1)'
				/>
			</template>

			<div class='row a-booking-form-action'>
				<div class="col">
					<template v-if='!edit'>
						<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
					</template>
				</div>
				<div class="col-auto">
					<v-button :small='!edit' :xxsmall='edit' color='green white--text' @click='transferSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SelectDefaults from "@/views/account/home/components/SelectDefaults";
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import { getAutocompleteTransferPoint, transferSearch } from '@/services/request';

	export default {
		data: () => ({
			open: false,
			form: {
				type: "both",
				transferFrom: null,
				transferTo: null,
				date: null,
				dateFrom: null,
				dateTo: null,
				adults: 1,
				childrenCount: 0,
				children: [],
				travelersIds: [],
				resetCache: false,
				isInOut: true,
				tourists: []
			},
			loading: false,
			errors: {},
			tripName: null
		}),
		computed: {
			items_types() {
				return [
					{id: "both", name: this.$t("account.booking.content.directions.both")},
					{id: "oneDirection", name: this.$t("account.booking.content.directions.one")},
				]
			},
			local_search_data() {
				return localStorage.getItem('local_search_data') ? JSON.parse(localStorage.getItem('local_search_data')) : {};
			}
		},
		props: ["edit", "searchData", "initialTripName"],
		components: {
			SelectDefaults,
			SelectPeople,
			SelectTourist
		},
		created() {
			if(this.local_search_data.transfers) {
				this.form = {...this.form, ...this.local_search_data.transfers};
			}
			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
			} else if(this.searchData) {
				this.form = {...this.form, ...this.searchData};
			}
			this.tripName = this.$route.query?.tripName ?? this.initialTripName;

			if(this.form.tourists?.length === 0 && !this.edit) {
				this.form.travelersIds = [];
			}

			this.form.childrenCount = this.form.children.length
		},
		methods: {
			getAutocompleteTransferPoint,
			transferSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
				let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				transferSearch({...this.form, ...orderId}).then(res => {

                    if(this.$route.params.storageId === res.data.storageId) {
                        this.$emit("update");
                    } else {
                        this.$router.push({
                            name: "transfers-search",
                            params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
                            query: {source: JSON.stringify(this.form), tripName: this.tripName}
                        })
                    }
                    localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, transfers: this.form}));


					if(this.$route.params.storageId !== res.data.storageId) {
                        let routeData = {
                            name: "transfers-search",
                            params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
                            query: {source: JSON.stringify(this.form), tripName: this.tripName}
                        };
                        // this.edit ? window.open(this.$router.resolve(routeData).href, '_blank') : this.$router.push(routeData);
                        this.$router.push(routeData);

                    }

                    this.$emit("update");
                    localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, transfers: this.form}));
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading = false)
			},
			changeTypes() {
				this.form.isInOut = this.form.type === 'both';

				if(!this.form.dateFrom) return;

				const timestamp = this.$moment(this.form.dateFrom).valueOf();
				if(this.form.type === 'both') {
					this.form.date = [ timestamp, timestamp ];
					this.form.dateFrom = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
					this.form.dateTo = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
				} else {
					delete this.form.dateTo;
					this.form.dateFrom = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
					this.form.date = timestamp;
				}
			},
			changeDate($event) {
				if(this.form.type === 'both') {
					this.form.date = $event[0] ? $event : null;
					this.form.dateFrom = this.$moment($event[0]).format('YYYY-MM-DD')
					this.form.dateTo = this.$moment($event[1]).format('YYYY-MM-DD')
				} else {
					this.form.date = $event;
					delete this.form.dateTo
					this.form.dateFrom = this.$moment($event).format('YYYY-MM-DD')
				}
			}
		},
		watch: {
			"form.tourists"() {
				localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, transfers: this.form}));
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-booking-form-transfers {
		&-tourists {
			padding-top: 16px;
		}
		&-edit {
			.a-booking-form-row {
				padding-bottom: 10px;
			}
			.a-booking-form-col {
				@include minw( $grid-breakpoints-xs ) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
				@include minw( $grid-breakpoints-sm ) {
					&:not(:last-child) {
						flex: none;
						flex-basis: 0;
						flex-grow: 1;
					}
					&:last-child {
						flex: 0 0 296px;
						max-width: 296px;
					}
				}
			}
		}
	}
</style>
